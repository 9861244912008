.company-list_wrapper {
  .empty-banner-content {
    margin-bottom: 10px;
  }
  .panel {
    @media screen and (min-width: 990px) {
      border: none;
    }
  }
  .row-disabled-company {
    opacity: 0.6;
  }
  .show-only-verification-requests {
    visibility: visible;
    position: relative;
    width: 10px;
    height: 10px;
    display: none;
  }
  .checbox-container {
    .filter-block input[type="checkbox"] + label + .has-tooltip i,
    .filter-block input[type="checkbox"] + label {
      line-height: 10px;
      margin-bottom: 0px;
    }
  }
  .link-simple {
    margin-bottom: -15px;
    padding-top: 11px;

    @media screen and (max-width: 990px) {
      margin-bottom: 15px;
    }
  }
  .verified-company-td {
    display: flex;
    justify-content: space-between;
    margin: 5px 0px;
    width: 100%;
    b {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    a {
      color: #323232;
    }
    .fa-check-circle {
      padding-top: 0px !important;
    }
  }
  .table {
    margin-bottom: 0;

    th {
      padding: 5px;
    }

    .td-no-entries {
      padding: 10px;
    }

    tr {
      min-height: 34px;
    }

    td {
      @media screen and (max-width: 990px) {
        width: 100%;
        display: inline;
        padding: 0px 10px;
        white-space: nowrap;
      }

      @media screen and (max-width: 767px) {
        display: block;
      }
    }

    td,
    th {
      text-align: center;
      vertical-align: middle;
    }

    .company {
      width: 24%;
      padding: 0px;
      font-weight: 500;
      a {
        display: flex;
        width: 100%;
        margin: 0px;
        padding-left: 19px;
        max-width: 100% !important;
      }

      @media screen and (max-width: 990px) {
        width: 100%;
        display: block;
      }
    }

    .rankings,
    .feedback,
    .status {
      width: 5%;
      a {
        text-transform: capitalize;
      }
    }

    .business-type,
    .user-company {
      width: 23%;
      @media screen and (max-width: 767px) {
        width: 15%;
      }
      a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 280px;
        display: block;
        margin: 0 auto;
      }
    }
    .business-type {
      .one-buss-type {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        max-width: 280px;
      }
    }
    .country {
      width: 17.5%;
    }

    .suggestions,
    .block_list {
      width: 10%;
    }

    .employees,
    .claims,
    .foundation-year {
      width: 7%;
    }

    .company-align-start {
      text-align: start;
      a {
        overflow: hidden;
        white-space: nowrap;
        max-width: 250px;
        text-overflow: ellipsis;
        float: left;
        color: #323232;
      }

      @media (max-width: 1319px) {
        a {
          max-width: 190px !important;
        }
      }
      .verif-company-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;
        min-width: 190px;
        max-width: 280px;
      }
      .fa-check-circle {
        margin-right: 5px;
        //max-width: 10%;
        float: left;
        justify-content: center;
        display: flex;
        align-items: center;
      }
    }

    .country-capitalize {
      text-transform: capitalize;
    }

    .table-header {
      height: 28px;
      background: #36454f;
      th {
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        border-bottom: none;
        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    .hidden-table-header {
      visibility: hidden;
    }

    .mobile-prefix {
      display: none;

      @media screen and (max-width: 990px) {
        display: inline;
        color: #999;
      }
    }
  }

  // .row-size {
  //   height: 54.6px;
  // }

  // .row-text-style {

  // }
}

@media screen and (max-width: 500px) {
  .header-top .filter-nav .priority-nav__dropdown.show {
    left: -6px;
  }
  .user-container-logged .user-block {
    top: 6px;
    right: 0px;
  }

  .panel-filter-search .search-container-secondary {
    max-width: 240px;
  }

  .company-list_wrapper .table .business-type {
    width: 100%;
    & > div {
      display: flex;
    }
  }
  .company-list_wrapper .table .company-align-start {
    .verif-company-container {
      justify-content: flex-start;
    }
    a {
      width: auto;
    }
  }
  .company-list_wrapper .table {
    .user-company {
      display: flex;
      a {
        margin: 0;
        overflow: visible;
      }
    }
  }

  .company-list_wrapper .table td,
  .company-list_wrapper .table th {
    text-align: start;
  }

  .table .user-company {
    a {
      padding-top: 5px;
      width: 276px !important;
    }
  }

  .company-list_wrapper .table .company-align-start a {
    max-width: 280px !important;
  }

  .company-list_wrapper .table .company-align-start a {
    max-width: 280px !important;
  }

  .profile-mini_wrapper {
    .user-data {
      margin-top: 10px;
    }
    .full-user-info .add-review {
      margin: 0px 15px 15px 15px !important;
    }
  }

  .user-entities {
    .table-no-entries {
      padding: 20px;
    }
  }
  .panel-company-header {
    .additional-info {
      flex-direction: column;
    }
    .additional-info-container-actions {
      position: unset !important;
      margin-top: 10px;
    }
  }
  .col-lg-12 {
    #tabsContent {
      margin-bottom: 20px;
    }
  }
  .filter-block label {
    min-width: 76px;
  }
  .verif-company-container {
    text-transform: uppercase;
  }
  .business-type {
    a {
      pointer-events: none;
      color: #323232;
    }
  }
  .company-list_wrapper .table .company {
    a {
      padding-left: 15px;
    }
  }
  .company-list_wrapper .verified-company-td {
    margin-right: 30px;
    .fa-check-circle {
      margin-left: 10px;
    }
  }
}
.show-only-expires-packets {
}
.checkboxes-container {
  display: flex;
  flex-direction: column;
}
.status-date-expired {
  width: 85px;
  font-size: 10px;
  padding-top: 0px;
  margin-top: -12px;
}
@media (min-width: 501px) and (max-width: 900px) {
  .col-lg-12 {
    #tabsContent {
      margin-bottom: 20px;
    }
  }
  .filter-block {
    display: flex;
    margin-left: 0px !important;
    & > div {
      margin-right: 5px;
    }
  }
  .company-list_wrapper .table td,
  .company-list_wrapper .table th {
    text-align: start;
  }
  .company-list_wrapper .verified-company-td {
    justify-content: flex-start;
    .fa-check-circle {
      margin-left: 5px;
    }
  }
  .table-responsive td > span,
  .table-responsive td > a,
  .table-responsive td > div {
    display: inline-flex;
  }
  .company-list_wrapper .table .user-company {
    a {
      display: inline-block;
    }
  }
}
.filter-wrapper {
  .banner-line-description {
    margin-right: 10px;
  }
}
.history-item {
  // line-height: 20px !important;
  padding: 5px 0px;
}
.td-history td {
  line-height: 20px !important;
}

